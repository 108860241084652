import { MAX_UPLOADED_FILES_SIZE, PAYMENT_METHOD, PAYMENT_PROVIDERS } from 'components/common/constants';
import { FieldError } from 'services/Error';

export const emailRegExp =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!\"#$%&'()*+,-.:;<=>?@\[\]^_`{/|\\}~])[\x20-\x7E]{7,30}$/;

export const registrationPasswordRegExp =
  // eslint-disable-next-line no-useless-escape
  /[^\x20-\x7E]/;

// eslint-disable-next-line no-useless-escape
export const passwordSpecialSymbolsRegExp = /[!\"#$%&'()*+,-.:;<=>?@\[\]^_`{/|\\}~]/;

export const messageRegExp = /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~\s]*$/;

export const noNumbersRegExp = /^[^\d]*$/;

export const replaceSpaces = (s) => s?.replace(/\s/g, '');

export const deleteNonLatin = (text) => text.replace(/[^A-Za-z0-9_ ]/gi, '');

export const formatSortCode = (string) => {
  const numericString = string?.replace(/\D/g, '')?.slice(0, 6);

  return numericString?.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1-$2-$3');
};

export const convertBytesToMegabytes = (bytes) => bytes / (1024 * 1024);

export const strToFloat = (str) => {
  if (!str) return NaN;

  // Remove commas which are used as thousands separators
  const formattedStr = str.replace(/,/g, '');

  const trimmed = formattedStr.trim().replace(/\.$/, '');
  const float = parseFloat(trimmed);

  return float === Number(trimmed) ? float : Number.NaN;
};

export const getErrorMessageForAlert = (i18n, error, isIgnored = true, paymentMethod = null) => {
  let translation = '';
  if (!error || (error instanceof FieldError && isIgnored)) return translation;

  const getSafeTranslation = (key, params) => {
    const translation = i18n.getMessage(key, params);
    // If the translation matches the key, it means there is no translation
    return translation === key ? i18n.getMessage('error.UNKNOWN_ERROR') : translation;
  };

  const translationId = `error.${error?.code}`;

  if (error?.code === 'REQUEST_HAS_BEEN_TERMINATED') {
    translation = getSafeTranslation(translationId, { maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE) });
  } else if (error?.code === 'AMOUNT_CHECK_FAILED' || error?.message === 'Provider amount exceed.') {
    if (paymentMethod && paymentMethod === PAYMENT_METHOD.SEPA_INSTANT) {
      translation = getSafeTranslation('error.AMOUNT_CHECK_FAILED.SEPA_INSTANT');
    } else if (paymentMethod && paymentMethod === PAYMENT_METHOD.FASTER_PAYMENTS) {
      translation = getSafeTranslation('error.AMOUNT_CHECK_FAILED.FASTER_PAYMENTS');
    } else {
      translation = getSafeTranslation('error.AMOUNT_CHECK_FAILED');
    }
  } else if (error?.code === 'PAYYE_CHECK_FAILED') {
    translation = error.message;
  } else if (error?.code === 'PAYMENT_CREATION_TIME_EXPIRED') {
    translation = getSafeTranslation('error.PAYMENT_CREATION_TIME_EXPIRED');
  } else if (error?.code === 'DOCUMENT_PROTECTED_WITH_PASSWORD') {
    translation = getSafeTranslation('error.DOCUMENT_PROTECTED_WITH_PASSWORD');
  } else if (error?.code === 'RECIPIENT_DATA_MISMATCH') {
    translation = getSafeTranslation('error.RECIPIENT_DATA_MISMATCH');
  } else if (error?.code === 'UNKNOWN_ERROR' || error?.message === 'Server Error') {
    translation = getSafeTranslation('error.SYSTEM_ERROR');
  } else if (error?.status === 504) {
    translation = getSafeTranslation('error.GETAWAY_TIME_OUT');
  } else if (error?.code === 'NOT_FOUND' && error?.message.includes('Exchange rate pair')) {
    const match = error?.message.match(/Exchange rate pair (\w{3}\/\w{3}) not found/);
    const currencyPair = match?.[1] || 'UNKNOWN/UNKNOWN';
    translation = getSafeTranslation('error.CURRENCY_PAIR_NOT_FOUND', { currencyPair });
  } else if (error?.message === 'Unsuccessful HTTP response') {
    translation = getSafeTranslation('error.UNSUCCESSFUL_HTTP_RESPONSE');
  } else {
    translation = getSafeTranslation(translationId);
  }

  if (translationId === translation) {
    return error?.message || error?.code;
  } else {
    return translation;
  }
};

export const prepareFieldErrors = (i18n, error, variable, isRegistration) => {
  let fieldsError = {};

  if (
    error?.fields &&
    error?.fields[0]?.code &&
    (error?.fields[0]?.code === 'NO_PAYMENT_METHODS_AVAILABLE' ||
      error?.fields[0]?.code === 'EXTERNAL_IBAN_VALIDATION_FAILED')
  ) {
    fieldsError = { iban: i18n.getMessage(`error.fieldError.${error?.fields[0].field}.${error?.fields[0].code}`) };
  } else {
    error?.fields &&
      Object.keys(error.fields).map((field) => {
        if (isRegistration && field === 'password' && error.fields[field] === 'Password') return;
        // eslint-disable-next-line max-len
        fieldsError[field.split('.').pop()] = i18n.getMessage(`error.fieldError.${field}.${error.fields[field]}`, {
          variable
        });
      });
  }

  return fieldsError;
};

export const debounce = (func, delay = 400) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      return func.apply(context, args);
    }, delay);
  };
};

export const MEDIA_QUERY = {
  HEADER_BUTTON_NAMES: '(min-width: 1050px)',
  DESKTOP: '(min-width: 769px)',
  NON_DESKTOP: '(min-width: 320px)',
  MOBILE: '(max-width: 499px)'
};

export const convertDateToServerFilter = (date) => {
  if (date !== null) {
    return `${date.getFullYear()}${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }`;
  } else {
    return '';
  }
};

export const convertDate = (date, isEndOfDay = false) => {
  if (date !== null) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }T${isEndOfDay ? '23:59:59' : '00:00:00'}`;
  }
  return '';
};

export const amountFormattedValue = (value, precision = 2) => {
  if (value === undefined || value === '' || value === null) {
    return '';
  }

  let stringValue = value.toString();
  const dotIndex = stringValue.indexOf('.');

  if (dotIndex !== -1) {
    stringValue = stringValue.slice(0, dotIndex + precision + 1);
  }

  const formattedValue = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  }).format(Number(stringValue));

  const [integerPart, fractionalPart] = formattedValue.split('.');

  if (!fractionalPart || fractionalPart.length < 2) {
    return formattedValue;
  }

  return `${integerPart}.${fractionalPart.replace(/0+$/, '').padEnd(2, '0')}`;
};

export const findAccountByCurrency = ({ accounts, currency, currencyForExclude }) => {
  if (!currency) {
    return accounts.find((account) => account.currency !== currencyForExclude);
  }
  return accounts.reduce((result, account) => {
    if (account.currency === currencyForExclude) {
      return undefined;
    }
    return account.currency === currency;
  }, undefined);
};

// FEES DATA FORMAT FUNCTIONS

export const format = (val) => {
  const formatted = val?.split('_').join(' ').toLowerCase();
  const capitalized = formatted?.charAt(0).toUpperCase() + formatted?.slice(1);
  return capitalized;
};

export const formServiceName = (obj) => {
  return format(obj.name);
};

export const formPaymentMethod = (obj) => {
  if (obj.transfer_type === 'EXCHANGE') {
    return format(obj.transfer_type);
  } else {
    return format(obj.payment_method);
  }
};

export const formTransactionType = (obj) => {
  if (obj.transfer_type === 'RETURN') {
    const capitalizedTransactionType = format(obj.transaction_type);
    const capitalizedTransferType = format(obj.transfer_type);
    return `${capitalizedTransactionType} ${capitalizedTransferType}`;
  } else {
    return format(obj.transaction_type);
  }
};

export function fileExtensionValidation(fileName) {
  const extension = fileName.split('.').pop();
  const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];

  return allowedExtensions.includes(extension);
}

export const trimTextContent = (text) => {
  if (!text) return text; // Return the text as-is if it's empty or null

  text = text.replace(/(\.\s*){2,}/g, '. '); // Remove extra spaces between multiple dots
  text = text.replace(/\s*\.\s*/g, '.'); // Remove spaces around single dots
  text = text.replace(/\s+/g, ' '); // Replace multiple consecutive spaces with a single space

  return text.trim(); // Trim spaces from the beginning and end of the text
};

export const formatAccountNumber = (accountNumber) => {
  if (!accountNumber || accountNumber.length < 12) {
    return accountNumber;
  }

  const firstPart = accountNumber.slice(0, 8);
  const lastPart = accountNumber.slice(-8);

  return `${firstPart}....${lastPart}`;
};

export const isCryptoProvider = (paymentProvider) => {
  return paymentProvider === PAYMENT_PROVIDERS.GRAPHEREX;
};

export const formatAmount = (value, precision = 2) => {
  if (!value) {
    return value;
  }
  const factor = Math.pow(10, precision);

  return Math.floor(value * factor) / factor;
};
