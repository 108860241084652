import { PAYMENT_METHOD } from '../../../constants';

export const getLatinLettersDigitsSymbolsRegExp = (symbols) => new RegExp(`^([A-Za-z0-9\\s${symbols}]*)$`, 'g');

export const getCustomerNameRegExp = () => {
  return new RegExp(CUSTOMER_NAME_REG_EXP, 'g');
};

export const getBankNameRegExp = () => {
  return new RegExp(BANK_NAME_REG_EXP, 'g');
};

export const getRegistrationNumberRegExp = () => {
  return new RegExp(BANK_NAME_REG_EXP, 'g');
};

export const getCityRegExp = () => {
  return new RegExp(CITY_REG_EXP, 'g');
};

export const getAddressRegExp = () => {
  return new RegExp(ADDRESS_REG_EXP, 'g');
};

export const getBicRegExp = () => {
  return new RegExp(UPPERCASE_LATIN_LETTERS_AND_DIGITS_REG_EXP, 'g');
};

export const getAccountNumberRegExp = () => {
  return new RegExp(ACCOUNT_NUMBER_REG_EXP, 'g');
};

export const LATIN_LETTERS_REG_EXP = /^([A-Za-z\s]*)$/g;

export const LATIN_LETTERS_AND_DIGITS_REG_EXP = /^([A-Za-z0-9\s]*)$/g;

export const UPPERCASE_LATIN_LETTERS_AND_DIGITS_REG_EXP = /^([A-Z0-9\s]*)$/g;

export const START_WITH_GB_REG_EXP = /^GB+.*$/g;

export const ONLY_DIGITS_REG_EXP = /^\d+$/g;

export const GBP_SORT_CODE_REG_EXP = /\d{2}-\d{2}-\d{2}/g;

export const CUSTOMER_NAME_REG_EXP = /^[A-Za-z0-9-/?:().,'+\r\n ]*$/g;

export const BANK_NAME_REG_EXP = /^([A-Za-z0-9\s/().,'+-]*)$/g;

export const CITY_REG_EXP = /^[A-Za-z0-9-]*$/g;

export const ACCOUNT_NUMBER_REG_EXP = /^([A-Za-z0-9-/\s]*)$/g;

export const ADDRESS_REG_EXP = /^[A-Za-z0-9\s/?:().,+'-]*$/g;

export const hasAtLeastTwoUniqueCharacters = (str) => {
  const uniqueChars = new Set(str.trim());
  return uniqueChars.size >= 2;
};

export const validateCommentFieldByPaymentMethod = (paymentMethod, comment) => {
  const otherAllowedCharsRegex = /^[A-Za-z0-9\s/?:().,+'-]*$/;
  const fasterPaymentsAllowedCharsRegex = /^[A-Za-z0-9\s,.-]*$/;
  const swiftPaymentsAllowedCharsRegex = /^[A-Za-z0-9\s/?:().,+'-]*$/;

  if (paymentMethod === PAYMENT_METHOD.FASTER_PAYMENTS) {
    if (comment.length < 2 || comment.length > 18 || !hasAtLeastTwoUniqueCharacters(comment)) {
      return false;
    } else {
      return fasterPaymentsAllowedCharsRegex.test(comment.trim());
    }
  } else if (paymentMethod === PAYMENT_METHOD.SWIFT) {
    if (comment.length < 2 || comment.length > 140 || !hasAtLeastTwoUniqueCharacters(comment)) {
      return false;
    } else {
      return swiftPaymentsAllowedCharsRegex.test(comment.trim());
    }
  } else {
    if (comment.length < 2 || comment.length > 140 || !hasAtLeastTwoUniqueCharacters(comment)) {
      return false;
    } else {
      return otherAllowedCharsRegex.test(comment.trim());
    }
  }
};
