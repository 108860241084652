/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Credentials from './Credentials';
import Sms from 'components/Authorization/Sms';
import { REGISTRATION_STATUSES, REGISTRATION_STEPS } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import { getConfirmationActionType } from 'services/authUtils';
import { resendPhoneRegistration } from 'services/requestAgent';
import { getErrorMessageForAlert } from 'services/utils';
import Alert from 'uikit/Alert/Alert';
import '../RegistrationStyles.scss';

const Registration = ({ authStore, registrationStore }) => {
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (registrationStore.currentStep !== REGISTRATION_STEPS.USER_REGISTRATION) {
      registrationStore.setCurrentRegistrationStep(REGISTRATION_STEPS.USER_REGISTRATION);
    }
  }, []);

  useEffect(() => {
    registrationStore.clearError();
    if (registrationStore.registrationStatus === REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED) {
      registrationStore.resetRegistrationStore();
      registrationStore.setIsAfterRegistrationUser(true);
      navigate(`${ROUTE_PATHS.CUSTOMER_REGISTRATION}`);
    }
  }, [registrationStore.registrationStatus]);

  useEffect(() => {
    if (registrationStore.confirmationId) {
      authStore.setUserAuthorizationData(
        registrationStore.confirmationId,
        registrationStore.generalFields.phoneNumber,
        getConfirmationActionType()
      );
      registrationStore.resetRegistrationStore();
      navigate(ROUTE_PATHS.LOGIN);
    }
  }, [registrationStore.confirmationId]);

  switch (registrationStore.registrationStatus) {
    case REGISTRATION_STATUSES.REGISTRATION_STATUS_PHONE_CONFIRMATION: {
      const handleCode = (code, disableSend) => {
        registrationStore.setCode(code);
        if (!disableSend) {
          registrationStore.sendCode();
        }
      };
      return (
        <div className='registration-form'>
          <h1 className='registration-title verification-title'> {i18n.getMessage('register.title.phone')} </h1>
          <Sms
            phone={registrationStore.generalFields.phoneNumber}
            sms={registrationStore.generalFields.code}
            error={registrationStore.error}
            resendCode={resendPhoneRegistration}
            handleSms={handleCode}
            isRegistration
          />
        </div>
      );
    }
    default:
      return (
        <div className='registration-form' id='form1'>
          <h1 className='registration-title user'>
            {' '}
            {i18n.getMessage('register.title.createUser', {
              paymentInstitution: process.env.REACT_APP_PAYMENT_INSTITUTION
            })}{' '}
          </h1>
          <Alert
            type={'warning'}
            message={registrationStore?.error && getErrorMessageForAlert(i18n, registrationStore.error)}
          />
          <div className='form-data'>
            <Credentials />
          </div>
        </div>
      );
  }
};

Registration.propTypes = {
  authStore: MobXPropTypes.observableObject,
  registrationStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  authStore: stores.authStore,
  registrationStore: stores.registrationStore
}))(observer(Registration));
