/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import BottomPartForm from './BottomPartForm';
import { cryptoModel } from './Models';
import { cryptoValidation } from './ModelValidators';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue, prepareFieldErrors, replaceSpaces, strToFloat } from 'services/utils';
import Input from 'uikit/Input/Input';

const CryptoWithdrawalForm = ({
  customerNumber,
  calculateCommission,
  checkTransfer,
  uploadDocuments,
  setIban,
  ibanCredentials,
  account,
  isRepeatPayment,
  paymentMethod,
  uploadedFiles,
  commission,
  removePaymentFile,
  error,
  isCommissionLoading,
  isLoading,
  isSuccess,
  validationProps,
  isFormResetAfterAccountChanging,
  setIsFormResetAfterAccountChanging,
  setIsRepeatPaymentStatus,
  validateCryptoWalletAddress
}) => {
  const i18n = useContext(i18nContext);
  const model = cryptoModel;
  const validationSchema = cryptoValidation({ ...validationProps, model });
  const serverFieldsErrors = prepareFieldErrors(i18n, error);
  const [disabled, setDisabled] = useState(true);
  const [showMethodCommission, setShowMethodCommission] = useState(false);
  const form = useFormik(validationSchema);
  const { values, errors, handleSubmit, handleChange, validateField, validateForm, resetForm } = form;

  useEffect(() => {
    const asyncOperations = async () => {
      if (isRepeatPayment) {
        setIsRepeatPaymentStatus(false);
        await handleRecipientWalletComplete(true);
      } else {
        resetForm();
        setIsFormResetAfterAccountChanging(true);
      }
    };

    asyncOperations();
  }, [account.wallet_number]);

  useEffect(() => {
    if (isSuccess) {
      resetForm({});
      setDisabled(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    const asyncOperations = async () => {
      if (!isFormResetAfterAccountChanging) return;

      if (values.amount) {
        await calculateCommission(customerNumber, {
          walletNumber: account?.wallet_number,
          paymentMethod: paymentMethod,
          amount: strToFloat(values.amount?.toString() || '')
        });
      }

      if (isRepeatPayment && !values.recipient_wallet) {
        await handleCheckTransfer(undefined);
      }
    };

    asyncOperations();
  }, [paymentMethod, isRepeatPayment, isFormResetAfterAccountChanging]);

  const handleCheckTransfer = async (fieldName) => {
    if (fieldName) await validateField(fieldName);
    if (values.recipient_wallet && values.amount) {
      const errorsResult = await validateForm();
      if (!Object.keys(errorsResult).length) {
        await checkTransfer(
          customerNumber,
          model({
            providerTableData: values,
            data: { wallet: account, paymentMethod, ...ibanCredentials }
          })
        );
        setShowMethodCommission(true);
        setDisabled(false);
      }
    }
  };

  const onAmountIBANChange = (e) => {
    setShowMethodCommission(false);
    handleChange(e);
    if (e.target.name === 'recipient_wallet') {
      setIban(e.target.value);
    }
  };

  const getCommissionValue = () => {
    if (!commission.value && !commission.currency) {
      return '0.00';
    }
    return `${amountFormattedValue(commission.value)} ${commission.currency}`;
  };

  const handleRecipientWalletComplete = async (causedOnBlur) => {
    if (causedOnBlur) await validateField('recipient_wallet');
    values.recipient_wallet &&
      (await validateCryptoWalletAddress({
        senderWalletNumber: account?.wallet_number,
        recipientCryptoWalletAddress: replaceSpaces(values.recipient_wallet),
        recipientCryptoNetwork: account?.network
      }));
    if (causedOnBlur) await handleCheckTransfer(undefined);
  };

  const bottomPartProps = {
    values,
    errors,
    error,
    handleChange,
    handleSubmit,
    serverFieldsErrors,
    onAmountIBANChange,
    calculateCommission,
    handleCheckTransfer,
    showMethodCommission,
    getCommissionValue,
    paymentMethod,
    paymentProvider: account.transfer_provider,
    isSubmitDisabled:
      disabled ||
      isLoading ||
      isCommissionLoading ||
      !!Object.keys(errors).length ||
      !!Object.keys(serverFieldsErrors).length ||
      !!error ||
      !paymentMethod,
    isLoading: isLoading || isCommissionLoading,
    uploadedFiles,
    uploadDocuments,
    removePaymentFile,
    customerNumber,
    currentWalletNumber: account?.wallet_number,
    commission
  };

  return (
    <>
      <div className={'inputs-wrapper'}>
        <Input
          isRequired={true}
          autoComplete={'off'}
          label={i18n.getMessage('transfer.form.recipientWallet.label')}
          name={'recipient_wallet'}
          value={values.recipient_wallet}
          error={!values.account_number && errors.recipient_wallet}
          onChange={onAmountIBANChange}
          onBlur={() => handleRecipientWalletComplete(true)}
          subText={
            ibanCredentials?.bank_name &&
            i18n.getMessage('transfer.form.bankName.subText', { bankName: ibanCredentials?.bank_name })
          }
        />
      </div>
      <BottomPartForm {...bottomPartProps} isCryptoForm={true} />
    </>
  );
};

CryptoWithdrawalForm.propTypes = {
  customerNumber: PropTypes.string,
  calculateCommission: PropTypes.func.isRequired,
  checkTransfer: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  setIban: PropTypes.func,
  setIsNonIban: PropTypes.func,
  ibanCredentials: PropTypes.object,
  clearIbanCredentials: PropTypes.func,
  checkIban: PropTypes.func.isRequired,
  isRepeatPayment: PropTypes.bool,
  account: PropTypes.object,
  paymentMethod: PropTypes.string,
  resetPaymentMethods: PropTypes.func,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  setError: PropTypes.func,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  isSuccess: PropTypes.bool,
  currency: PropTypes.string,
  validationProps: PropTypes.object.isRequired,
  isFormResetAfterAccountChanging: PropTypes.bool,
  setIsFormResetAfterAccountChanging: PropTypes.func,
  setIsRepeatPaymentStatus: PropTypes.func,
  validateCryptoWalletAddress: PropTypes.func
};

export default CryptoWithdrawalForm;
