import * as Yup from 'yup';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { PAYMENT_METHOD, RECIPIENT_TYPES } from 'components/common/constants';
import { getMaxDescriptionSize } from '../../utils';
import {
  LATIN_LETTERS_AND_DIGITS_REG_EXP,
  START_WITH_GB_REG_EXP,
  getCustomerNameRegExp,
  getBankNameRegExp,
  getBicRegExp,
  getRegistrationNumberRegExp,
  getCityRegExp,
  getAddressRegExp,
  validateCommentFieldByPaymentMethod,
  getAccountNumberRegExp,
  hasAtLeastTwoUniqueCharacters
} from './utils';
import { trimTextContent } from 'services/utils';

export default ({
  i18n,
  onChange,
  onSubmit,
  customerNumber,
  model,
  currency,
  previousTransaction,
  paymentMethod,
  providerType
}) => ({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    amount: `${previousTransaction?.amount || ''}`,
    recipient_wallet: previousTransaction?.counterparty?.iban,
    recipient_type: RECIPIENT_TYPES[previousTransaction?.recipient_type] || RECIPIENT_TYPES.COMPANY,
    company_name: previousTransaction?.counterparty?.party_name,
    first_name: previousTransaction?.counterparty?.party_name.split(' ')[0] || null,
    last_name: previousTransaction?.counterparty?.party_name.split(' ')[1] || null,
    comment: previousTransaction?.comment,
    documents: null,
    registration_number: previousTransaction?.counterparty?.registration_number,
    beneficiary_city: previousTransaction?.counterparty?.party_city,
    beneficiary_post_code: previousTransaction?.counterparty?.post_code,
    beneficiary_address: previousTransaction?.counterparty?.party_address,
    beneficiary_country: previousTransaction?.counterparty?.party_country,
    bank_country: previousTransaction?.counterparty?.bank_country,
    bank_name: previousTransaction?.counterparty?.bank_name,
    account_number: previousTransaction?.counterparty?.account_number || '',
    bic: previousTransaction?.counterparty?.bic || ''
  },
  initialStatus: { company_name: true, recipient_wallet: true },
  validationSchema: Yup.object().shape(
    {
      recipient_wallet: Yup.string().when('account_number', {
        is: (account_number) => !account_number,
        then: (schema) =>
          schema
            .nullable(true)
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .max(34, i18n.getMessage('client.validation.message.maxSize', { size: 34 }))
            .trim()
            // trick for using `currency` variable in checking value in validation
            .when('recipient_type', (recipient_type, schema) => {
              if (currency === 'GBP' && paymentMethod !== PAYMENT_METHOD.SWIFT) {
                return schema.matches(START_WITH_GB_REG_EXP, {
                  message: i18n.getMessage('client.validation.message.startWithGB')
                });
              }
              return schema;
            })
            .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
              message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
            }),
        otherwise: (schema) => schema.nullable(true)
      }),
      bank_name: Yup.string().when('recipient_wallet', {
        is: (recipient_wallet) => !recipient_wallet,
        then: (schema) =>
          schema
            .nullable(true)
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .max(60, i18n.getMessage('client.validation.message.maxSize', { size: 60 }))
            .matches(getBankNameRegExp(), {
              message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                availableSymbols: "“.“, “,“, “-“, “/“, “(“, “)“, “'“, “+“, “Space“"
              })
            }),
        otherwise: (schema) => schema.nullable(true)
      }),
      account_number: Yup.string().when('recipient_wallet', {
        is: (recipient_wallet) => !recipient_wallet,
        then: (schema) =>
          schema
            .nullable(true)
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .max(50, i18n.getMessage('client.validation.message.maxSize', { size: 50 }))
            .min(7, i18n.getMessage('client.validation.message.minSize', { size: 7 }))
            .matches(getAccountNumberRegExp(), {
              message: i18n.getMessage('transfer.form.error.accountNumber')
            }),
        otherwise: (schema) => schema.nullable(true)
      }),
      bic: Yup.string().when('recipient_wallet', {
        is: (recipient_wallet) => !recipient_wallet,
        then: (schema) =>
          schema
            .nullable(true)
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .max(11, i18n.getMessage('client.validation.message.maxSize', { size: 11 }))
            .min(8, i18n.getMessage('client.validation.message.minSize', { size: 8 }))
            .matches(getBicRegExp(), {
              message: i18n.getMessage('client.validation.message.isOnlyUppercaseLatinLettersAndDigits')
            }),
        otherwise: (schema) => schema.nullable(true)
      }),
      registration_number: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
        .matches(getRegistrationNumberRegExp(), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: "“.“, “,“, “-“, “/“, “(“, “)“, “'“, “+“, “Space“"
          })
        }),
      beneficiary_city: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
        .matches(getCityRegExp(), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: '“-“'
          })
        }),
      beneficiary_post_code: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .test('valid-post-code', i18n.getMessage('client.validation.message.invalidPostCode'), function (value) {
          const { beneficiary_country } = this.parent;
          let validationResult = true;

          if (!beneficiary_country) {
            return validationResult;
          }

          if (postcodeValidatorExistsForCountry(beneficiary_country)) {
            validationResult = postcodeValidator(value, beneficiary_country);
          }

          return validationResult;
        })
        .max(15, i18n.getMessage('client.validation.message.maxSize', { size: 15 })),
      beneficiary_address: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .min(2, i18n.getMessage('client.validation.message.minSize', { size: 2 }))
        .max(140, i18n.getMessage('client.validation.message.maxSize', { size: 140 }))
        .matches(getAddressRegExp(), {
          message: i18n.getMessage('transfer.form.error.address')
        })
        .test('address-validation', i18n.getMessage('transfer.form.error.address'), (address) =>
          hasAtLeastTwoUniqueCharacters(trimTextContent(address))
        ),
      amount: Yup.string().nullable(true).required(i18n.getMessage('client.validation.message.isEmpty')),
      recipient_type: Yup.string().nullable(false).required(i18n.getMessage('client.validation.message.isEmpty')),
      company_name: Yup.string()
        .nullable(true)
        .when('recipient_type', {
          is: RECIPIENT_TYPES.COMPANY,
          then: (schema) =>
            schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
              .matches(getCustomerNameRegExp(), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: "“.“, “,“, “-“, “/“, “:“, “?“, “(“, “)“, “'“, “+“, “CrLf“, “Space“"
                })
              }),
          otherwise: (schema) => schema.nullable(true)
        }),
      first_name: Yup.string()
        .nullable(true)
        .when('recipient_type', {
          is: RECIPIENT_TYPES.INDIVIDUAL,
          then: (schema) =>
            schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
              .matches(getCustomerNameRegExp(), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: "“.“, “,“, “-“, “/“, “:“, “?“, “(“, “)“, “'“, “+“, “CrLf“, “Space“"
                })
              }),
          otherwise: (schema) => schema.nullable(true)
        }),
      last_name: Yup.string()
        .nullable(true)
        .when('recipient_type', {
          is: RECIPIENT_TYPES.INDIVIDUAL,
          then: (schema) =>
            schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .max(35, i18n.getMessage('client.validation.message.maxSize', { size: 35 }))
              .matches(getCustomerNameRegExp(), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: "“.“, “,“, “-“, “/“, “:“, “?“, “(“, “)“, “'“, “+“, “CrLf“, “Space“"
                })
              }),
          otherwise: (schema) => schema.nullable(true)
        }),
      comment: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .test(
          'openpayd-comment-validation',
          paymentMethod === PAYMENT_METHOD.SWIFT
            ? i18n.getMessage('transfer.form.error.comment.SWIFT')
            : i18n.getMessage('transfer.form.error.comment.OTHER_METHODS'),
          (comment) => validateCommentFieldByPaymentMethod(paymentMethod, trimTextContent(comment))
        )
        .max(
          getMaxDescriptionSize(providerType, paymentMethod, currency),
          i18n.getMessage('client.validation.message.maxSize', {
            size: getMaxDescriptionSize(providerType, paymentMethod, currency)
          })
        )
    },
    [['account_number', 'recipient_wallet']]
  ),
  onSubmit: (values) => {
    onChange(values);
    onSubmit({ customerNumber, providerData: values, model });
  }
});
